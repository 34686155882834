.Page404 {
  padding: 45px 0 0 0;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.description {
  text-align: center;
  margin-bottom: 45px;
}
