.footer {
  color: #848484;
  font-size: 10px;
  padding: 10px;
  line-height: 1;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  background-color: #eee;
  font-family: 'Roboto' !important;

  p {
    font-family: 'Roboto' !important;
  }

  a {
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #848484;
    }
  }

  .ofertaLink {
    display: flex;
    text-align: center;
    flex-direction: column;
    line-height: 20px;
    width: 100%;
    margin-bottom: 10px;
  }
}

.linksWrapper {
  display: flex;
  gap: 10px;
  @media (max-width: 400px) {
    flex-direction: column;
    gap: 0;
  }
}

.link {
  margin-bottom: 5px;
  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #848484;
  }
}

