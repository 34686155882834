.wrapper {
  background-color: #eee;
  min-width: 240px;
  max-width: 730px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.headerSupport {
  padding: 5px 0;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
  }
}
