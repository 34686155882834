.overlay {
  transition: all 0.5s;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: auto;
  height: auto;
  font-size: 80px;
  opacity: 0.8;
  background: none;
  border: 0;
  transform: translate(-50%, -50%);

  img {
    transform: scale(0.9);
  }

  &:hover {
    opacity: 1;

    img {
      transform: scale(1);
    }

  }
}

.bigButton {
  line-height: 1em;
  overflow: hidden;
  margin: auto;
  padding: 1.5em;
  text-align: center;
  font-family: Tele2RussiaText-Regular;
  
  &_wrapper {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .4);
    @media (max-width: 700px) {
      width: 50px;
      height: 50px;
    }
  }

  &_text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

   @media (max-width: 700px) {
     padding: .5em;
   }
}





.playing {
  .overlay {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}



.video {
  display: block;
  width: 100%;
  height: 100%;
  &.playing {
    .togglePlayBtn {
      opacity: 0;
    }
  }
  &::-webkit-media-controls {
    display:none !important;
  }
  &:-webkit-full-screen {
    background-color:transparent;
  }
}

.playing {
  width: auto !important;
  height: auto !important;
  z-index: 10;
  top: 25%;
  position: fixed;
  @media (min-width: 768px) {
    top: 10%;
  }
}


.player {
  width: 100%;
  padding: 0;
  border: 0;
  border-radius: 0;
  margin: 0;
  &:-moz-full-screen {
    height:100%
  }
  &:-webkit-full-screen {
    height:100%
  }
  &:-ms-fullscreen {
    height:100%
  }
  &:fullscreen {
    height:100%
  }
}

.time {
  float: left;
  user-select: none;
  cursor: pointer;
  color: white;
  pointer-events: none;
  margin-left: 15px;
  font-size: 15px;
}

.playPause {
  float: left;
  cursor: pointer;
  color: white;
  margin-left: 15px;
  font-size: 15px;
  svg {
    width: 10px;
  }
}

.controls {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

}

.controlsBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.controls button {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  svg {
    width: 18px;
    color: white;
  }
}

.btnFullScreen {
  margin-left: 10px;
  svg {
    width: 13px !important;
  }
}



.volume {
  &[type=range] {
    margin: 0;
  }
  &[type=range]:focus::-ms-fill-upper {
    background: #404040;
  }
  &[type=range]:focus::-ms-fill-lower {
    background: #333333;
  }
  &[type=range]::-ms-thumb {
    width: 15px;
    height: 15px;
    background: #ffffff;
    border: 0px solid #333333;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 0px;
  }
  &[type=range]::-ms-fill-upper {
    background: #333333;
    border: 0;
    border-radius: 2px;
  }
  &[type=range]::-ms-fill-lower {
    background: #262626;
    border: 0;
    border-radius: 2px;
  }
  &[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 7.6px 0;
    color: transparent;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  &[type=range]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #ffffff;
    border: 0px solid #333333;
    border-radius: 12px;
    cursor: pointer;
  }
  &[type=range]::-moz-range-track {
    background: #333333;
    border: 0;
    border-radius: 1px;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  &[type=range]:focus::-webkit-slider-runnable-track {
    background: #404040;
  }
  &[type=range]::-webkit-slider-thumb {
    margin-top: -5px;
    width: 15px;
    height: 15px;
    background: #ffffff;
    border: 0px solid #333333;
    border-radius: 12px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &[type=range]::-webkit-slider-runnable-track {
    background: #333333;
    border: 0;
    border-radius: 1px;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  &[type=range]::-webkit-slider-thumb {
    margin-top: -5px;
    width: 15px;
    height: 15px;
    background: #ffffff;
    border: 0px solid #333333;
    border-radius: 12px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &[type=range]::-webkit-slider-runnable-track {
    background: #333333;
    border: 0;
    border-radius: 1px;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  &[type=range]:focus {
    outline: none;
  }
  &[type=range] {
    width: 100%;
    margin: 5px 0;
    background-color: transparent;
    -webkit-appearance: none;
    max-width: 100px;
  }
}


.progress {
  color: #e2e2e2;
  font-size: 0;
  width: 100%;
  height: 5px;
  border: none;
  margin-right: 0;
  background: black;
  border-radius: 0;
  vertical-align: top;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;

  &[value]::-webkit-progress-value {
    background-color: white;
  }
  &[value]::-webkit-progress-bar {
    background-color: black;
    border-radius: 0px;
  }
  &::-moz-progress-bar {
    color: white;
    background: black;
  }
}

.btnClose {
    position: absolute;
    top: -32px;
    right: 6px;
    height: 26px;
    width: 26px;
    border: 2px solid #fff;
    cursor: pointer;
    background: none;
    font-family: Arial,
    sans-serif;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    &::after {
        position: absolute;
        content: '';
        display: block;
        width: 2px;
        height: 14px;
        top: 4px;
        left: 10px;
        background: #fff;
        transform: rotate(45deg);
    }
    &::before {
        transform: rotate(-45deg);
        position: absolute;
        content: '';
        display: block;
        width: 2px;
        height: 14px;
        top: 4px;
        left: 10px;
        background: #fff;
    }
}

