.promoBannerItem {
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    height: 70px;
    @media only screen and (min-width: 650px) {
        height: 115px;
    }
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%) skewX(-30deg);
        display: block;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(90deg, rgba(0,0,0,0), hsla(0,0%,100%,.3) 90%, rgba(0,0,0,0));
        animation: flash 5s ease-in-out infinite;
        z-index: 100;
        padding: 0 10px;
    }
}

    .promoLogoIcon {
        padding: 0 15px;
        z-index: 9;
        display: flex;
        align-items: center;
        & svg {
            width: 20px;
            @media only screen and (min-width: 450px) {
                width: 30px;
            }
            @media only screen and (min-width: 768px) {
                width: 40px;
            }
        }
        @media only screen and (min-width: 450px) {
            padding: 0 25px;
        }
        @media only screen and (min-width: 550px) {
            padding: 0 35px;
        }
    }


    .image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        @media only screen and (max-width: 415px) {
            object-fit: contain;
        }
    }

@keyframes flash {
    0% {
        left: -150%
    }
    20% {
        left: 100%
    }
    100% {
        left: 150%
    }
}
