.Videos {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.navItem {
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px 10px 5px;
  width: 100%;
  text-align: center;
  margin: 10px;
  color: #3d414a;
  font-size: 18px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  box-sizing: border-box;
  min-height: 55px;
  &.active, &:hover {
    border-bottom: 10px solid #ffca23;
  }

  @media (max-width: 470px) {
    font-size: 14px;
    margin: 0;
  }
}

.video {
  padding: 5px;
  margin-bottom: 10px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 450px;
  cursor: pointer;

  @media (max-width: 700px) {
    height: 220px;
  }
}

.videoPlay {
  width: 98%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  background: black;
  position: relative;
  overflow: hidden;
  @media (max-width: 700px) {
    max-height: 70%;
  }
}

.videoText {
  padding: 10px;
  display: flex;
  flex-direction: row;
  height: 10%;
  justify-content: space-between;
  overflow: hidden;
  
  a {
    text-decoration: none;
    color: #000;
    align-self: center;
  }

}

.title {
  line-height: 1;
  font-size: 22px;
  font-weight: 300;
  margin: auto 0;
  font-family: Tele2RussiaText-Regular;

  @media (max-width: 470px) {
    font-size: 18px;
  }
}

.player {
  width: 100%;
  height: 100%;
}

.overlay {
  transition: all 0.5s;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: auto;
  height: auto;
  font-size: 80px;
  opacity: 0.6;
  background: none;
  border: 0;
  transform: translate(-50%, -50%);

  &:hover {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.playing {
  .overlay {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.bottom {
  justify-content: space-between;
  display: flex;
  color: #727a8b;
  font-size: 14px;
  margin: auto 0;
}

.like {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAUCAMAAABRYFY8AAAAUVBMVEUAAAByeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeotyeot6ZjlaAAAAGnRSTlMA9OHUyqedZ0EJOPn45sa+sJSHfnBaJBwN7HX5690AAAB7SURBVBjTdcjZDoMgFADRYRPQuq/l/z+0CaUm6u08TQ6At0p1PgLRd29lPQBHn3J2Y7Pf7Q/ApVJbt791EJJUwInu0KJrXqI3f1xhRDeMoo/UotcwCDwAu3qw2gHWh6/kphtPlKoLV5xVMsN88syloLPqwK24mMYskdIHa/YhhJcKHcMAAAAASUVORK5CYII=);
  background-size: 15px;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position-y: 4px;
  border: 0;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
}

.like.disable {
  pointer-events: none;
}

.active {
  filter: invert(17%) sepia(39%) saturate(3233%) hue-rotate(205deg) brightness(95%) contrast(87%);
}

.look {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUCAMAAADbT899AAAAgVBMVEUAAABvdY5yeotyeotyeotzeopzeYxze4pyeotyeotyeotyeYtudZBzeop0fIlxeYxzeopyeotxeYtzfIpyeotzeotyeoxxeo17gYNyeotyeotvd45pcJRrcJNeY59kaptpco9zeYt3fohnaJhbYKR2e4hrc5FVW6hWW6hyeot1fYg+ipxsAAAAKXRSTlMABv3XgywCG+/p4d3Gvo2IVTh12svEtkQ7eR3Lv3xiW1U+IyIcERB7etxgulsAAADrSURBVCjPhVLXFoIwDK0UiyhQ9lKWm/7/B5qmQMEej3nJzU3SjIZoYZGdU9eluR0xYsqu9cZFvHb37S9SIT0upa7UIi226YElaT90GHNCX2KLrx65X5CKZztW4c5s7xMsHOuMColkP/mPaGYSD5wPUmdIHTHiqvxjCLixoFIDIFTc6UbII1X4ABVLBSNC6oOC5ycJxikYmrYVtGGs00QHfwO2JQQuCUo4SwndZA+9vQT43wD6pUk9pscAl5yXoJinx9SLqsxF/Vz1OK3a/Kyasdr4LJQiEzgLpdi/yArjYLr1wXSQboo+uRX5AQRKLTvIB2QuAAAAAElFTkSuQmCC);
  background-size: 20px;
  padding-left: 25px;
  background-repeat: no-repeat;
  background-position-y: 4px;
  mix-blend-mode: exclusion;
}

.isWatched {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(226, 226, 226, 0.7);
  font-size: 13px;
  z-index: 1;
  padding: 6px 12px;
}

.moreButton {
  background: linear-gradient(180deg, rgba(199, 199, 199, 0) 0%, rgba(199, 199, 199, 1) 50%, rgba(199, 199, 199, 0) 100%);
  z-index: 9;
  border: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  align-items: center;
  padding-bottom: 10px;
  font-family: Tele2RussiaText-Regular;
}