.loader {
  //position: fixed;
  background-color: #eee;
  z-index: 999;
  position: relative;
  height: 70px;
  //top: 0;
  //left: 0;
  //bottom: 0;
  //right: 0;
}

.whirlpool {
  transform: translate(-50%, -50%);
  height: 49px;
  width: 49px;
  animation: cssload-rotate 1150ms linear infinite;
  &::before, &::after, & {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid #cccccc;
    border-left-color: #000000;
    border-radius: 100%;
  }
  &::before {
    content: "";
    transform: translate(-50%, -50%);
    height: 43px;
    width: 43px;
    animation: cssload-rotate 1150ms linear infinite;
  }
  &::after {
    content: "";
    transform: translate(-50%, -50%);
    height: 55px;
    width: 55px;
    animation: cssload-rotate 2300ms linear infinite;
  }
}

@keyframes cssload-rotate {
  100% {
   transform: translate(-50%, -50%) rotate(360deg);
  }
}
