.header {
  margin-bottom: 10px;
  padding: 0 10px;
  background: url('../../assets/images/banner.jpg') top / cover no-repeat;
}

.header_small {
  background-position: center 70%;
}

.wrapper {
  box-sizing: border-box;
  padding: 0 10px;
}

.logoLink {
  display: block;
  width: 40%;
  margin: 10px 0;
  font-size: 30px;
}

.logo {
  width: 100%;
}

img.smile {
  height: 110px;
  position: absolute;
  top: 45px;
  left: 4%;

  @media (max-width: 470px) {
    top: 13px;
  }
}

.title {
  font-family: PFBeauSansProBold, sans-serif;
  padding: 25px 10px 10px 140px;
  margin: 0;
  font-size: 42px;
  text-transform: uppercase;
  line-height: .7;
  @media (max-width: 470px) {
    font-size: 24px;
    padding-left: calc(56% - 6vmin);
    padding-top: 45px;
  }
}

.titleSpan {
  font-size: 35px;
  @media (max-width: 470px) {
    font-size: 18px;
  }
}