.title {
  font-family: Tele2RussiaText-Regular;
  font-size: 36px;
  text-align: center;
  padding: 15px;
  @media (max-width: 470px) {
    font-size: 26px;
  }
}

.date {
  margin-left: 10px;
}