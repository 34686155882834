.CardPageHeader{
  height: 110px;
}

.wrapper {
  background: #fff;
}

.title {
  font-family: Tele2RussiaText-Regular;
  font-size: 36px;
  text-align: center;
  margin: 0 10px 10px 10px;
  padding: 20px;
  background: #eeeeee;

  @media (max-width: 470px) {
    font-size: 26px;
  }
}

.date {
  margin-left: 10px;
}

.moreWrapper {
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.moreCard {

  &_wrapper {
    flex: 1 1 40%;
    height: 13em;
    margin: 5px;
    position: relative;
    cursor: pointer;

    @media (max-width: 700px) {
      height: 7em;
    }
  }

  &_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_title {
    position: absolute;
    color: #fff;
    font-size: 10px;
    padding-left: 5px;
    bottom: 0;
    z-index: 9;
    width: 98.5%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &_button {
    font-size: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-family: Tele2RussiaText-Regular;
  }
}